<template>
    <!-- 查看账号续费套餐 -->
    <div class="box-card" style="text-align: left;">
        <el-form :model="batchAddForm" ref="batchAddFormRef" label-width="120px"
            class="form_box">
            <el-form-item label="套餐包名称：" >{{ batchAddForm.account_package_name }}
            </el-form-item>
            <el-form-item label="充值月数：">{{ batchAddForm.account_package_month }}
            </el-form-item>
            <el-form-item label="套餐价格：">{{ batchAddForm.account_package_price }}
                元
            </el-form-item>
            <el-form-item label="附赠优惠劵：">{{ batchAddForm.is_coupon?'是':'否' }}
            </el-form-item>
            <el-form-item label="优惠劵：" v-if="batchAddForm.is_coupon">
                <div v-for="(item, index) in batchAddForm.coupons" :key="index" style="display:flex;margin-bottom:20px;">
                    {{ item.coupon_name }}  {{ item.coupon_price }}元/张 {{ item.coupon_count }} 张
                </div>
            </el-form-item>
            <el-form-item label="优惠劵时效：" v-if="batchAddForm.is_coupon">{{ batchAddForm.valid_day }} 天
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import { addAccountCombo, updateAccountCombo, delAccountCombo, getCouponsSelectList, setAccountComboStatus, getAccountComboPageList, getAccountComboDetails } from '@/api/discountCoupon'
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['id'],
  data () {
    return {
      batchAddForm: {
        account_package_name: '', // 套餐包名称
        account_package_month: null, // 套餐时长（月）
        account_package_price: null, // 套餐金额
        is_coupon: false, // 是否附赠优惠
        coupons: [{
          coupon_id: '', // 优惠券id
          coupon_name: '', // 优惠券名称
          coupon_type: null, // 枚举 优惠券类型 1.金额券 2.小时券 3.优免券
          coupon_price: null, // 优惠券价格
          coupon_count: null // 优惠券数量
        }],
        valid_day: null // 优惠券时效
      },
      couponsSelectList: []
    }
  },
  created () {
    this.fngetCouponsSelectList()
    if (this.id) this.fngetAccountComboDetails()
  },
  mounted () { },
  computed: {},
  watch: {},
  methods: {
    ...mapMutations(['setQueryStatus']),
    async fngetAccountComboDetails () {
      const res = await getAccountComboDetails({ _id: this.id })
      console.log(res)
      if (res && res.Code === 200) {
        this.batchAddForm.account_package_name = res.Data.account_package_name
        this.batchAddForm.account_package_month = res.Data.account_package_month
        this.batchAddForm.account_package_price = res.Data.account_package_price
        this.batchAddForm.is_coupon = res.Data.is_coupon
        if (res.Data.is_coupon) {
          this.batchAddForm.coupons = res.Data.coupons
          this.batchAddForm.valid_day = res.Data.valid_day
        } else {
          this.batchAddForm.coupons = [{
            coupon_id: '', // 优惠券id
            coupon_name: '', // 优惠券名称
            coupon_type: null, // 枚举 优惠券类型 1.金额券 2.小时券 3.优免券
            coupon_price: null, // 优惠券价格
            coupon_count: null // 优惠券数量
          }]
        }
      }
    },
    async fngetCouponsSelectList () {
      const res = await getCouponsSelectList()
      this.couponsSelectList = res && res.Code === 200 && res.Data ? res.Data : []
    }
  }
}
</script>
<style scoped lang="less">
.form_box {
    max-height: 700px;
    overflow: hidden;
    overflow-y: auto;

    /deep/ .el-input {
        width: 350px;
    }

    /deep/ .el-textarea {
        width: 80%;
    }

    .NumberInput {
        width: 150px;
        margin: 0 10px;
    }
}
</style>
